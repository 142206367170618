<template>
  <v-layout class="loan-detail-content" column justify-space-around>
    <v-card class="px-2 top-content">
      <v-layout justify-space-between>
        <v-card flat class="circle-container">
          <van-circle
            layer-color="#353535"
            :color="color"
            stroke-width="80"
            size="170"
            fill="transparent"
            :rate="(layouts.data.repaymentLoan.unpaidPrincipal / layouts.data.repaymentLoan.totalPrincipal) * 100"
            v-model="currentRate"
          >
            <p class="circle-top">Unpaid Principal</p>
            <p class="circle-middle">{{ assist.currency.format(layouts.data.repaymentLoan.unpaidPrincipal) }}</p>
          </van-circle>
        </v-card>
        <v-card class="top-content-right" flat>
          <v-card-title class="loan-number">{{ layouts.data.repaymentLoan.loanNo }}</v-card-title>
          <v-layout class="py-2" justify-space-between>
            <div class="field-label">Original Principal</div>
            <div class="field-value">{{ assist.currency.format(layouts.data.repaymentLoan.totalPrincipal) }}</div>
          </v-layout>
          <v-layout class="py-2" justify-space-between>
            <div class="field-label">Paid Principal</div>
            <div class="field-value">{{ assist.currency.format(layouts.data.repaymentLoan.totalPrincipal - layouts.data.repaymentLoan.unpaidPrincipal) }}</div>
          </v-layout>
          <v-layout class="py-2" justify-space-between>
            <div class="field-label">Unpaid Principal</div>
            <div class="field-value">{{ assist.currency.format(layouts.data.repaymentLoan.unpaidPrincipal) }}</div>
          </v-layout>
          <v-card-actions class="px-0">
            <v-btn class="schedule-btn" min-width="200" @click="goPaymentPlan">Payment Schedule</v-btn>
          </v-card-actions>
        </v-card>
      </v-layout>
    </v-card>
    <v-card flat color="transparent">
      <v-layout class="bottom-content" v-for="(item, index) in layouts.settings.properties" :key="index"
                justify-space-between>
        <v-flex class="bottom-content-head">
          <img :src="item.icon" class="details-img">
          <div class="field-label">{{ item.text }}</div>
        </v-flex>
        <v-flex class="field-value">{{ renderValue(item.fieldKey) }}</v-flex>
      </v-layout>
    </v-card>

    <v-dialog
      v-model="layouts.dialog.paymentSchedule"
      persistent
      width="700"
      class="preview-dialog"
    >
      <v-card>
        <v-card-actions>
          <v-card-title class="f-s-20">Payment Schedule</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon color="var(--theme_primary)" text @click="layouts.dialog.paymentSchedule = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-data-table
            :headers="layouts.settings.headers"
            :items=layouts.data.repaymentLoan.installments
            :server-items-length="999"
            hide-default-footer
            fixed-header
            height="500">
            <template
              v-slot:body="{ items }"
            >
              <tbody>
              <tr
                v-for="(item,index) in items"
                :key="index"
                :style="item.transactionStatus === assist.dataType.TransactionStatusEnum.CHECKED.value ? 'background-color: #d2eeec; color: #4caf50!important' : ''"
              >
                <td>{{ item.transactionDate }}</td>
                <td>{{ assist.currency.format(item.amount) }}</td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-layout>
</template>

<script>
import { Tool, DataType, Currency } from '@/assets/js/util'
import { Circle } from 'vant/es'
import 'vant/es/circle/style'
export default {
  name: 'repayment-loan-details',
  components: {
    [Circle.name]: Circle
  },
  data () {
    return {
      assist: {
        tool: new Tool(),
        currency: new Currency(),
        dataType: DataType
      },
      currentRate: 0,
      color: {
        '0%': '#f5f5f5',
        '100%': 'var(--theme_secondary)'
      },
      layouts: {
        data: {
          totalAmount: 0,
          previewInstallmentList: [],
          repaymentLoan: {
            loanId: null,
            loanNo: '',
            totalPrincipal: 0,
            installments: [],
            apr: '',
            debitCount: '',
            regularAmount: '',
            firstDebitDate: '',
            lastDebitDate: '',
            unpaidPrincipal: ''
          }
        },
        settings: {
          headers: [
            {
              text: 'Date',
              align: 'start',
              sortable: false,
              value: 'transactionDate',
              class: 'header-style-left',
              cellClass: 'cell'
            },
            {
              text: 'Amount',
              template: '{img}',
              value: 'amount',
              sortable: false,
              class: 'header-style',
              cellClass: 'cell'
            }
          ],
          properties: [
            {
              icon: require('@/assets/images/web/repayment/Slice 30@2x.png'),
              text: '# of Installments',
              disabled: true,
              isCanEdit: false,
              fieldKey: 'debitCount'
            },
            {
              icon: require('@/assets/images/web/repayment/Slice 34@2x.png'),
              text: '$ of Regular Installment',
              disabled: true,
              isCanEdit: false,
              fieldKey: 'regularAmount'
            },
            {
              icon: require('@/assets/images/web/repayment/Slice 32@2x.png'),
              text: 'First Due Date',
              disabled: true,
              isCanEdit: false,
              fieldKey: 'firstDebitDate'
            },
            {
              icon: require('@/assets/images/web/repayment/Slice 31@2x.png'),
              text: 'Maturity Date',
              disabled: true,
              isCanEdit: false,
              fieldKey: 'lastDebitDate'
            },
            {
              icon: require('@/assets/images/web/repayment/Slice 28@2x.png'),
              text: 'APR',
              disabled: true,
              isCanEdit: false,
              fieldKey: 'apr'
            }
          ]
        },
        dialog: {
          paymentSchedule: false
        }
      },
      loanId: null
    }
  },
  mounted () {
    const loan = this.$store.getters.getRepaymentLoanInfo
    this.layouts.data.repaymentLoan = Object.assign(loan)
  },
  methods: {
    goPaymentPlan () {
      this.layouts.dialog.paymentSchedule = true
    },
    renderValue (key) {
      if (key === 'apr') {
        return this.assist.tool.formatPercent(this.layouts.data.repaymentLoan[key])
      } else if (key === 'regularAmount') {
        return this.assist.currency.format(this.layouts.data.repaymentLoan[key])
      } else {
        return this.layouts.data.repaymentLoan[key]
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.loan-detail-content
  width: 44%
  margin-left: 28%
  height: 100%

.top-content
  background: var(--theme_primary)

  &-right
    background: var(--theme_primary)
    width: 60%
    padding: 0 12px
    min-height: 230px

    & .field-label
      color: #F5F5F5

    & .field-value
      color: #F5F5F5

    .loan-number
      font-size: 22px
      height: 25px
      padding: 0
      margin: 12px 0 12px 0
      color: #F5F5F5

    .schedule-btn
      height: 40px !important
      background: var(--theme_primary) !important
      color: #F5F5F5
      border: 1px solid #F5F5F5

// loan circle style
.circle-container
  width: 40%
  display: flex
  align-items: center
  justify-content: center
  background: var(--theme_primary)

  .circle-top
    position: absolute
    top: 36%
    left: 0
    width: 100%
    font-size: 14px
    color: #F5F5F5

  .circle-middle
    position: absolute
    top: 52%
    left: 0
    width: 100%
    font-size: 28px
    font-weight: 700
    color: var(--theme_secondary)

.loan-detail-title-card
  width: 220px
  height: 220px

.bottom-content
  padding: 12px

  &-head
    display: flex

    & .field-label
      margin-top: 10px

  & .field-value
    text-align: right
    color: #9A9A9A

.details-img
  width: 32px
  height: 32px
  margin: 4px
</style>
